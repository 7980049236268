<template>
  <div>
    <b-row>
      <b-col>
        <div id="component-breadcrumbs">
          <b-breadcrumb
            class="breadcrumb-slash"
            :items="breadcrumbItems"
          />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h2>Vehicle Management</h2>
      </b-col>
    </b-row>
    <hr>
    <div class="row mb-1">
      <!-- add button -->
      <div class="col-4 pr-0 pl-0 D-flex justify-space-arround">
        <b-button
          block
          variant="primary"
          style="
  justify-content: space-around;
  display: flex;
  border-radius: 0;
  width: max-content;
"
          @click="$router.push('/admin/utility/add-vehicle')"
        >
          <span>+ Add Vehicle</span>
        </b-button>
      </div>
    </div>
    <b-row>
      <!-- Table -->
      <b-col class="border-top-dark min-height-10 rounded-sm">
        <b-row
          class="bg-light border-left-dark border-right-dark height-50 d-flex align-items-center"
          :class="{'border-bottom-dark': !Vehicles.length}"
        >
          <b-col md="4">
            Vehicle Make/Model
          </b-col>
          <b-col md="3">
            Unit Number
          </b-col>
          <b-col md="3">
            Department/Classification
          </b-col>
          <b-col md="1">
            Actions
          </b-col>
        </b-row>
        <b-row
          v-for="(item, index) of Vehicles"
          :key="index"
          class="border-left-dark border-right-dark d-flex align-items-center"
          :class="{'border-bottom-dark': index + 1 === Vehicles.length}"
        >
          <b-col>
            <b-row class="pt-1 border-top">
              <b-col md="4">
                <span class="text-nowrap">{{ item.Name }}</span>
              </b-col>
              <b-col md="3">
                <span>{{ item.UnitNumber }}</span>
              </b-col>
              <b-col md="3">
                <span>{{ item.Department }}</span>
              </b-col>
              <b-col md="1">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item>
                      <span @click="editVehicle(item.VehicleID)">Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item>
                      <span @click="deleteVehicle(item.VehicleID)">Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- MODAL -->
    <b-modal
      id="modal-delete-vehicle"
      ok-only
      ok-variant="danger"
      ok-title="Delete vehicle"
      modal-class="modal-danger"
      centered
      title="Confirm Delete vehicle"
      @ok="confirmDeleteVehicle()"
    >
      <div v-if="deletedVehicle !== null">
        You are about to delete this vehicle.
        <small>Are you sure you want to do this?</small>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {BBreadcrumb, BButton, BCol, BDropdown, BDropdownItem, BModal, BRow} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import APIService from "@core/utils/APIService";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const apiService = new APIService();

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BModal,
    BBreadcrumb,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      Vehicles: [],
      deletedVehicle: null,
    }
  },
  computed: {
    breadcrumbItems: function() {
      return [
        {
          text: 'Admin',
        },
        {
          text: 'Utility',
          href: '/admin/utility'
        },
        {
          text: "Manage Vehicles",
        },
      ]
    },
  },
  created() {
    this.loadVehicles();
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "InfoIcon",
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          }
      );
    },
    loadVehicles() {
      apiService
          .get("vehicles/list")
          .then(res => {
            this.Vehicles = res.data;
          })
    },
    editVehicle(val) {
      this.$router.push('/admin/utility/edit-vehicle/' + val)
    },
    deleteVehicle(val) {
      this.deletedVehicle = val;
      this.$bvModal.show("modal-delete-vehicle");
    },
    confirmDeleteVehicle() {
      apiService
          .delete("vehicle/" + this.deletedVehicle)
          .then((res) => {
            if (res) {
              this.showToast("success", "top-center", 4000, "Vehicle deleted");
              this.loadVehicles();
            }
          })
    },
  }
}
</script>

<style scoped>

</style>